import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../components/Layout';
import SubscriptionForm from '../components/SubscriptionForm';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import '../assets/styles/pages/subscribe.scss';

const ApplyThankYou = () => {
  return (
    <Layout
      title="Application received | TEFL Iberia"
      description="Many thanks for your application!"
    >
      <div className="subscribe-page">
        <div className="container">
          <div className="wrapper">
            <SubscriptionForm />
            <Link to="/" className="c-btn c-btn--red mt-50">
              Return to the home page
              <IconArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
